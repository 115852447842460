import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"

import Layout from "../components/layout"
import Seo from "../components/seo"
import FormPoc from "../components/form-poc"
import TextGradient from "../components/ui/text-gradient"
import ListItemWithIcon from "../components/ui/list-item-with-icon"
import CheckedIcon from "../svg/check.svg"
import Logo from "../svg/logo.svg"

const options = {
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      const type = node?.data?.target?.__typename || null

      if (type !== "ContentfulTextGradient") {
        return children
      }

      return <TextGradient>{node?.data?.target?.content}</TextGradient>
    },
    [BLOCKS.HEADING_1]: (node, children) => {
      return <h1 className="text-white">{children}</h1>
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p className="text-white">{children}</p>
    },
  },
}

export default function POC({ location }) {
  const { data } = useStaticQuery(
    graphql`
      query {
        data: contentfulPagePoc {
          title
          description
          featuredImage {
            gatsbyImageData
            resize(width: 512, quality: 80) {
              src
            }
          }
          heroContent {
            raw
            references {
              ... on ContentfulTextGradient {
                contentful_id
                __typename
                content
              }
            }
          }
          checkList
        }
      }
    `
  )

  const title = data?.title || ""
  const description = data?.description || ""
  const thumbnail = data?.featuredImage?.resize?.src || ""
  const content = data?.heroContent || ""
  const checkList = data?.checkList || []

  return (
    <Layout location={location} hideHeader hideFooter>
      <Seo
        title={title}
        description={description}
        image={thumbnail}
        url={location.href}
      />

      <div className="bg-white h-screen">
        <div className="w-full flex flex-wrap">
          <div className="w-full md:w-1/2 relative shadow-2xl">
            <div className="flex flex-col sticky top-0 w-full bg-gradient-to-t from-blue-royal via-black to-black md:h-screen">
              <div className="flex flex-col justify-center max-w-xl w-full my-auto mx-auto px-4 md:justify-start md:px-12">
                <div className="py-8">
                  <Link to="/" className="mb-6 inline-block">
                    <Logo className="h-8 w-auto text-light" />
                  </Link>

                  {content && renderRichText(content, options)}

                  {checkList && (
                    <ul className="my-12 list-none relative mx-auto text-left text-white lg:text-xl">
                      {checkList.map(item => (
                        <ListItemWithIcon
                          key={item}
                          text={item}
                          className="pl-9"
                          icon={<CheckedIcon className="list-icon h-6 w-6" />}
                        />
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="w-full md:w-1/2 flex flex-col">
            <div className="flex flex-col justify-center max-w-xl w-full my-auto mx-auto px-4 md:justify-start md:px-12">
              <FormPoc />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
