import * as React from "react"
import { navigate } from "gatsby"
import { useForm } from "react-hook-form"
import ErrorMessage from "../components/ui/error-message"

import { errorStyles, encode } from "../helpers"

import ButtonWithLoader from "./ui/button-with-loader"

const FormPoc = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" })
  const [alert, setalert] = React.useState(null)
  const [isLoading, setisLoading] = React.useState(false)
  const formEl = React.useRef(null)

  const onSubmit = (data, e) => {
    e.preventDefault()
    const form = e.target

    setisLoading(true)

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...data,
      }),
    })
      .then(() => {
        navigate("/poc-thank-you")
      })
      .catch(error => {
        setalert("Form could not be submitted. Please try again.")
        setisLoading(false)
        formEl.current.reset()
        console.log(error)
      })
  }

  return (
    <div className="relative">
      {alert && <p>{alert}</p>}

      <form
        ref={formEl}
        name="poc"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        className="my-12"
        onSubmit={handleSubmit(onSubmit)}>
        <div hidden>
          <label>
            Don’t fill this out: <input name="bot-field" />
          </label>
        </div>

        <fieldset className="form-columns-2">
          <div>
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              name="firstName"
              id="firstName"
              style={errors.firstName && errorStyles}
              {...register("firstName", { required: true, maxLength: 80 })}
            />
            {errors.firstName?.type === "required" && <ErrorMessage />}
          </div>

          <div>
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              name="lastName"
              id="lastName"
              style={errors.lastName && errorStyles}
              {...register("lastName", { required: true, maxLength: 80 })}
            />
            {errors.lastName?.type === "required" && <ErrorMessage />}
          </div>
        </fieldset>

        <fieldset>
          <label htmlFor="email">Your Email</label>
          <input
            type="email"
            name="email"
            id="email"
            style={errors.email && errorStyles}
            {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
          />
          {errors.email?.type === "required" && <ErrorMessage />}
        </fieldset>

        <fieldset>
          <label htmlFor="phoneNumber">Phone number</label>
          <input
            type="number"
            name="phoneNumber"
            id="phoneNumber"
            style={errors.phoneNumber && errorStyles}
            {...register("phoneNumber", { required: true })}
          />
          {errors.phoneNumber?.type === "required" && <ErrorMessage />}
        </fieldset>

        <fieldset>
          <label htmlFor="website">Your company website address</label>
          <input
            type="url"
            name="website"
            id="website"
            style={errors.website && errorStyles}
            placeholder="https://"
            {...register("website", { required: true, maxLength: 80 })}
          />
          {errors.website?.type === "required" && <ErrorMessage />}
        </fieldset>

        <fieldset>
          <label htmlFor="role">What is your role?</label>
          <input
            type="text"
            name="role"
            id="role"
            style={errors.role && errorStyles}
            {...register("role", { required: true, maxLength: 80 })}
          />
          {errors.role?.type === "required" && <ErrorMessage />}
        </fieldset>

        <fieldset>
          <label htmlFor="engineers">
            How many software engineers you have?
          </label>
          <input
            type="text"
            name="engineers"
            id="engineers"
            style={errors.engineers && errorStyles}
            {...register("engineers", { required: true, maxLength: 80 })}
          />
          {errors.engineers?.type === "required" && <ErrorMessage />}
        </fieldset>

        <fieldset>
          <label htmlFor="peopleInvolved">
            How many people are going to be involved in the POC on your end?
          </label>
          <input
            type="text"
            name="peopleInvolved"
            id="peopleInvolved"
            style={errors.peopleInvolved && errorStyles}
            {...register("peopleInvolved", { required: true, maxLength: 80 })}
          />
          {errors.peopleInvolved?.type === "required" && <ErrorMessage />}
        </fieldset>

        <fieldset>
          <label htmlFor="decisionMaker">Who is the decision maker?</label>
          <input
            type="text"
            name="decisionMaker"
            id="decisionMaker"
            style={errors.decisionMaker && errorStyles}
            {...register("decisionMaker", { required: true, maxLength: 80 })}
          />
          {errors.decisionMaker?.type === "required" && <ErrorMessage />}
        </fieldset>

        <ButtonWithLoader label="Submit information" isLoading={isLoading} />
      </form>
    </div>
  )
}

export default FormPoc
